<template>
  <v-container fluid class="pa-0 register">
   <back-to-store :title="$t('text.recoverPassword')"></back-to-store>
    <v-container fluid class="pa-16">
      <v-form>
        <p class="text-h5 black-grin text-center text-lg-left">{{$t('profile.email')}}</p>
        <v-text-field outlined v-model="email"></v-text-field>

        <p class="p josefin-light black-grin text-center text-lg-left">
          {{$t('text.recoverPasswordMessage')}}
        </p>

        <v-row class="py-5">
          <v-col cols="12" lg="6" class="justify-center d-flex justify-lg-start">
            <v-btn class="btn_second" >
              {{$t('text.btnReset')}}
            </v-btn>
          </v-col>
          <v-col cols="12" lg="6" class="justify-center d-flex justify-lg-end">
            <router-link to="/log-in">
              <v-btn class="transparent px-0  mt-3" elevation="0">
                <p class=" black-grin text-h5 text-right ">{{$t('menu.logIn')}}</p>
              </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import backToStore from '@/components/home/back-to-store.vue';

export default {
  components: { backToStore },
  data() {
    return {
      email: null,
    };
  },
  methods: {
    ...mapActions("customer", ["resetPassword"])
  }
};
</script>
